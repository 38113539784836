import { Media, hideMDDown, hideSMUp, mbSize } from '@renderbus/common/theme'
import styled from 'styled-components'
import SidebarPicture from '../images/picture-giveback-old-user/sidebar-picture.png'

export const ContentContainer = styled.div`
  text-align: center;
  background: rgba(234, 246, 255, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 147px;
  .hideSMUp {
    ${hideSMUp};
  }
  .hideMDDown {
    ${hideMDDown};
  }
  ${Media.lessThan(Media.small)} {
    padding-bottom: ${mbSize(65)};
  }
`

export const BannerWrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  .effect-price-billboard {
    height: 100%;
    z-index: 0;
  }
  ${Media.lessThan(Media.small)} {
  }
`
export const ShareButton = styled.div`
  width: 145px;
  height: 36px;
  line-height: 36px;
  background: #8ae7c0;
  border-radius: 18px;
  font-size: 15px;
  font-weight: bold;
  color: #0b1e16;
  position: absolute;
  z-index: 1;
  top: 129px;
  @media screen and (max-width: 1500px) {
    top: 79px;
  }
  ${Media.lessThan(Media.small)} {
    width: 98px;
    height: 20px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
    color: #062116;
    line-height: 20px;
    top: 76px;
  }
`
export const ContentImg = styled.img`
  position: absolute;
  z-index: 1;
  top: 100px;
  left: 50%;
  width: 753px;
  ${hideMDDown};
`
export const ContentMbImg = styled.img`
  position: absolute;
  width: ${mbSize(750)};
  z-index: 1;
  top: ${mbSize(284)};
  left: 50%;
  transform: translate(-50%, 0);
  ${hideSMUp};
`

export const BannerImg = styled.img`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`
export const SectionTitleWrapper = styled.div`
  position: absolute;
  top: -70px;
  left: 50%;
  width: 585px;
  height: 152px;
  img {
    width: 100%;
    position: relative;
    z-index: 1;
  }
  transform: translate(-50%, 0);

  ${Media.lessThan(Media.small)} {
    width: ${mbSize(585)};
    top: ${mbSize(-66)};
  }
`
export const SectionTitleTip = styled.div`
  position: absolute;
  left: 50%;
  top: 84px;
  transform: translate(-50%, 0);
  .section-title-tip {
    font-weight: bold;
    color: rgba(31, 109, 204, 1);
    font-size: 32px;
    white-space: nowrap;
    &.section-title-tip-sub {
      font-size: 24px;
    }
    &-amount {
      color: rgba(217, 43, 43, 1);
      font-size: 28px;
    }
    &-attention {
      font-size: 40px;
    }
  }
  ${Media.lessThan(Media.small)} {
    top: ${mbSize(98)};
    .section-title-tip {
      font-size: 16px;
      &.section-title-tip-sub {
        font-size: ${mbSize(24)};
        margin-top: 4px;
      }
      &.activity-time {
        margin-top: 15px;
      }
      &-amount {
        font-size: ${mbSize(28)};
      }
      &-attention {
        font-size: 40px;
        font-size: 16px;
        font-family: Alimama ShuHeiTi;
        display: block;
        text-align: center;
        margin-bottom: ${mbSize(29)};
      }
    }
  }
`
export const Section = styled.div`
  width: 1400px;
  height: ${p => (p.height ? `${p.height}px` : '1080px')};
  position: ${p => (p.position ? p.position : 'relative')};
  margin-top: ${p => `${p.top}px`};
  padding: 0 60px;
  display: flex;
  flex-direction: column;
  background: linear-gradient(220deg, #e5f1ff 0%, #cce3ff 100%);
  border-radius: 50px;
  border: 2px solid rgba(255, 255, 255, 0.6);
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(719)};
    height: max-content;
    margin-top: ${p => mbSize(p.mbTop)};
    padding: 0;
    border-radius: ${mbSize(30)};
  }
`
export const RechargeCardWrapper = styled.div`
  display: flex;
  margin-top: 63px;
  width: 100%;
  height: max-content;
  cursor: pointer;
  justify-content: center;
  ${Media.lessThan(Media.small)} {
    justify-content: flex-start;
    margin-top: ${mbSize(43)};
    flex-wrap: wrap;
    margin-left: ${mbSize(35)};
    gap: ${mbSize(20)};
  }
`
export const RechargeCard = styled.div`
  position: relative;
  width: 440px;
  height: 280px;
  img {
    position: absolute;
    z-index: 1;
    width: 100%;
    left: -15px;
    top: -25px;
  }
  .recharge-card {
    ${hideMDDown};
  }
  .recharge-card-mb {
    ${hideSMUp};
  }

  ${Media.lessThan(Media.small)} {
    width: ${mbSize(310)};
    height: ${mbSize(177)};
    img {
      left: 0;
      top: 0;
      width: ${mbSize(310)};
    }
  }
`
export const RechargeCardContent = styled.div`
  position: absolute;
  z-index: 3;
  padding: 45px 60px;
  text-align: left;
  width: 100%;
  left: -15px;
  .card-amount {
    font-size: 24px;
    font-weight: 400;
  }
  .card-total {
    font-size: 18px;
    span {
      font-size: 40px;
      font-weight: bold;
      line-height: 40px;
      margin-left: 6px;
    }
  }
  .card-coupon {
    font-size: 18px;
    font-weight: 400;
    margin-top: 21px;
    text-align: center;
  }
  ${Media.lessThan(Media.small)} {
    padding: ${mbSize(30)} ${mbSize(30)};
    left: 0;
    .card-amount {
      font-size: ${mbSize(24)};
    }
    .card-total {
      font-size: ${mbSize(20)};
      margin-top: ${mbSize(6)};
      span {
        font-size: ${mbSize(40)};
        line-height: ${mbSize(36)};
        margin-left: 3px;
      }
    }
    .card-coupon {
      font-size: ${mbSize(20)};
      margin-top: ${mbSize(10)};
    }
  }
`

export const RechargeInputWrapper = styled.div`
  position: relative;
  text-align: left;
  margin-top: -25px;
  .recipe-115 {
    position: absolute;
    width: 167px;
    left: 190px;
    top: 6px;
  }
  ${Media.lessThan(Media.small)} {
    .recipe-115 {
      width: 102px;
      left: 110px;
      top: 5px;
    }
    padding-left: ${mbSize(38)};
    margin-top: ${mbSize(26)};
  }
`
export const RechargeInputLabel = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #1f6dcc;
  margin-bottom: 18px;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(24)};
    margin-bottom: ${mbSize(8)};
  }
`
export const CurrencySymbol = styled.span`
  position: absolute;
  left: 21px;
  line-height: 70px;
  font-size: 24px;
  color: #e3fffa;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(30)};
    top: ${mbSize(72)};
    left: ${mbSize(56)};
    line-height: ${mbSize(22)};
  }
`
export const RechargeInput = styled.input`
  width: 300px;
  height: 70px;
  line-height: 70px;
  background: #1f6dcc;
  border: 1px solid ${({ isWarning }) => (isWarning ? '#ff4d4f' : '#1F6DCC')};
  border-radius: 10px;
  padding: 0px;
  padding-left: 35px;
  font-size: 24px;
  color: #e3fffa;
  &:focus {
    border-color: ${({ isWarning }) => (isWarning ? '#ff4d4f' : '#1F6DCC')};
  }
  &:focus-visible {
    outline: none;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(30)};
    width: ${mbSize(280)};
    height: ${mbSize(77)};
    padding: ${mbSize(20)};
    padding-left: ${mbSize(36)};
  }
`

export const RechargeInputTip = styled.div`
  display: inline-block;
  height: 48px;
  background: rgba(31, 109, 204, 0.8);
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #fefefe;
  padding: 0 18px;
  margin-left: 20px;
  line-height: 48px;
  .divider {
    display: inline-block;
    width: 1px;
    height: 14px;
    background: #fefefe;
    border: 1px solid #fefefe;
    margin: 0 8px;
  }
  ${Media.lessThan(Media.small)} {
    display: inline-flex;
    align-items: center;
    height: ${mbSize(48)};
    font-size: ${mbSize(18)};
    line-height: 24px;
    padding: 0 ${mbSize(18)};
    margin-left: ${mbSize(14)};
    border-radius: ${mbSize(10)};
    .divider {
      height: 8px;
      margin: 0 4px;
    }
  }
`
export const RechargeInputImg = styled.img`
  position: relative;
  width: max-content;
  margin: 16px 0 12px 0;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(578)};
    margin: ${mbSize(39)} 0 ${mbSize(64)} ${mbSize(37)};
  }
`
export const ButtonImg = styled.img`
  position: relative;
  width: max-content;
  align-self: center;
  cursor: pointer;
  display: ${p => (p.show ? 'block' : 'none')};
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(369)};
    &.mb-btn {
      display: block;
    }
  }
`
export const ImgButton = styled.div`
  position: relative;
  width: 372px;
  height: max-content;
  left: 50%;
  transform: translate(-50%, 0);
  top: 24px;
  cursor: pointer;
  img {
    width: 100%;
  }
  p {
    margin: 0;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 42%;
    font-size: 30px;
    font-weight: 500;
    color: #ffffff;
  }
  &.invite-button-style {
    position: none;
    left: 0;
    transform: none;
    top: 0;
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(328)};
    top: ${mbSize(57)};
    p {
      font-size: ${mbSize(30)};
      top: 44%;
    }
  }
`
export const ButtonWrapper = styled.div`
  align-self: center;
  height: 129px;
  cursor: pointer;
  ${Media.lessThan(Media.small)} {
    height: max-content;
  }
`
export const RechargeRule = styled.div`
  position: relative;
  display: flex;
  margin-top: 62px;
  width: 1280px;
  /* height: 340px; */
  height: 420px;
  background: linear-gradient(45deg, rgba(38, 137, 255, 0.2) 0%, rgba(70, 190, 224, 0.1) 100%);
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  padding: 0 60px 0 26px;
  justify-content: space-between;
  align-items: center;
  img {
    width: 230px;
    height: 230px;
    margin: 0;
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(672)};
    height: max-content;
    align-self: center;
    margin: ${mbSize(100)} 0 ${mbSize(60)} 0;
    padding: ${mbSize(39)} ${mbSize(40)} ${mbSize(58)} ${mbSize(21)};
    border-radius: ${mbSize(20)};
    img {
      display: none;
    }
  }
`
export const MobileQrCodeWrapper = styled.div`
  display: none;
  position: relative;
  background: linear-gradient(0deg, #0f8b73 0%, #1f9d85 100%);
  border-radius: 20px;
  padding: 27px 0 20px;
  margin: 0 12px;
  img {
    width: 225px;
    height: 225px;
    margin: 27px 0 21px;
  }
  & .title {
    font-size: 16px;
    font-weight: bold;
    color: #003a35;
  }
  & .footer {
    font-size: 14px;
    font-weight: bold;
    color: #36eadb;
  }
  ${Media.lessThan(Media.small)} {
    display: block;
  }
`
export const RechargeTitle = styled.div`
  color: rgba(31, 109, 204, 1);
  font-size: 24px;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 26px;
  text-align: left;
  ${Media.lessThan(Media.small)} {
    margin: 0 0 ${mbSize(26)} 0;
    font-size: ${mbSize(24)};
    font-weight: bold;
  }
`

export const RechargeRuleItem = styled.div`
  display: flex;
  font-size: 22px;
  line-height: 42px;
  text-align: start;
  color: rgba(31, 109, 204, 1);
  position: relative;
  img {
    position: relative;
    top: 11px;
    margin-right: 10px;
    width: 19px;
    height: 19px;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(24)};
    line-height: ${mbSize(42)};
    align-items: flex-start;
    margin-bottom: 0;
    img {
      display: inline-block;
      top: ${mbSize(12)};
      width: ${mbSize(21)};
      height: ${mbSize(21)};
      margin-right: ${mbSize(6)};
    }
  }
`
export const QRCodeImg = styled.img`
  position: relative;
  margin-top: 171px;
  width: max-content;
  margin-left: 50%;
  transform: translate(-50%, 0);
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(528)};
    margin-top: ${mbSize(175)};
    margin-bottom: ${mbSize(74)};
  }
`

export const InviteWrapper = styled.div`
  position: relative;
  width: 100%;
  background: linear-gradient(45deg, rgba(38, 137, 255, 0.2) 0%, rgba(70, 190, 224, 0.1) 100%);
  border: 2px solid rgba(255, 255, 255, 0.3);
  margin-top: 215px;
  border-radius: 20px;
  padding: 52px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  > img {
    margin-bottom: 30px;
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(685)};
    height: max-content;
    align-self: center;
    padding: ${mbSize(41)} 0 ${mbSize(27)} 0;
    margin-top: ${mbSize(295)};
    > img {
      width: ${mbSize(639)};
    }
  }
`

export const InviteRule = styled.div`
  position: relative;
  padding: 39px 0 0 50px;
  text-align: start;
  ${Media.lessThan(Media.small)} {
    padding: ${mbSize(39)} 0 ${mbSize(60)} ${mbSize(43)};
  }
`
export const InviteRuleTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: rgba(31, 109, 204, 1);
  line-height: 1;
  margin: 0 0 28px 7px;
  ${Media.lessThan(Media.small)} {
    margin: 0 0 ${mbSize(26)} 0;
    font-size: ${mbSize(24)};
    margin: 0 0 ${mbSize(30)} 0;
  }
`
export const IniteRuleItem = styled.div`
  display: flex;
  font-size: 24px;
  line-height: 1;
  margin-bottom: 16px;
  color: rgba(31, 109, 204, 1);
  img {
    margin-right: 5px;
    width: 21px;
    height: 21px;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(24)};
    margin-bottom: ${mbSize(16)};
    img {
      width: ${mbSize(21)};
      height: ${mbSize(21)};
    }
  }
`
export const BannerHeaderWrap = styled.div`
  position: relative;
  width: 1400px;
  ${Media.lessThan(Media.small)} {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: ${mbSize(200)};
  }
`
export const GoldImg = styled.img`
  width: 54px;
  height: 84px;
  position: absolute;
  z-index: 1;
  top: 244px;
  left: 39px;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(55)};
    height: ${mbSize(78)};
    top: ${mbSize(40)};
    left: ${mbSize(129)};
  }
`
export const Title = styled.div`
  position: absolute;
  z-index: 1;
  top: 285px;
  left: 102px;
  img {
    width: 325px;
  }
  ${Media.lessThan(Media.small)} {
    position: relative;
    top: unset;
    left: unset;
    margin: ${mbSize(24)} 0 ${mbSize(17)};
    img {
      width: ${mbSize(371)};
    }
  }
`
export const TitleSecond = styled.div`
  position: absolute;
  top: 370px;
  left: 110px;
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
  letter-spacing: 1px;
  span {
    font-weight: bold;
  }
  ${Media.lessThan(Media.small)} {
    position: relative;
    top: unset;
    left: unset;
    font-size: ${mbSize(30)};
    letter-spacing: ${mbSize(1)};
  }
`
export const TitleThird = styled.div`
  position: absolute;
  top: 412px;
  left: 110px;
  font-size: 18px;
  font-weight: 400;
  color: #dddddd;
  line-height: 18px;
  span {
    font-weight: bold;
  }
  ${Media.lessThan(Media.small)} {
    position: relative;
    top: unset;
    left: unset;
    font-size: ${mbSize(24)};
    margin-top: ${mbSize(19)};
  }
`
export const TitleTip = styled.div`
  position: absolute;
  top: 220px;
  left: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 248px;
  height: 44px;
  border-radius: 22px;
  border: 2px solid #ffecb2;
  font-size: 20px;
  font-weight: 400;
  color: #ffecb2;
  line-height: 24px;
  > img {
    width: 22px;
    height: 20px;
  }
  ${Media.lessThan(Media.small)} {
    position: relative;
    top: unset;
    left: unset;
    width: ${mbSize(340)};
    height: ${mbSize(50)};
    font-size: ${mbSize(24)};
    gap: ${mbSize(10)};
    border: 1px solid #ffecb2;
    > img {
      width: ${mbSize(26)};
      height: ${mbSize(25)};
    }
  }
`
export const InputWarningSpan = styled.div`
  color: #ff4d4f;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(20)};
  }
`

export const Sidebar = styled.div`
  position: fixed;
  right: 0;
  top: calc(60% - 132px);
  right: 20px;
  z-index: 10;
  img {
    width: 50px;
    cursor: pointer;
    top: calc(60% - 132px);
    position: fixed;
    right: 20px;
  }
  &:hover {
    width: 200px;
    height: 140px;
    ::before {
      content: '';
      position: fixed;
      width: 280px;
      height: 140px;
      background-image: url(${SidebarPicture});
      background-size: 100% 100%;
      right: 90px;
    }
  }
`
